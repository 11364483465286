<template>
  <v-app>
    <v-main>
      <HelloWorld />
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',

  components: {
    HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.v-application {
  font-family: 'Poppins', sans-serif;
}

#btn-validate {
  background-color: #f16657 !important;
  color: aliceblue !important;
}

#btn-validate:hover {
  background-color: red !important;
  color: aliceblue !important;
}

#btn-clean {
  background-color: #5c3f98 !important;
  color: aliceblue !important;
}

#btn-clean:hover {
  background-color: gray !important;
  color: aliceblue !important;
}

#title {
  color: #5c3f98 !important;
}

fieldset {
  border-color: #5c3f98 !important;
}
</style>