<template>
  <v-row justify="center">
    <v-col cols="12" md="10">
      <v-form ref="form" v-model="valid">
        <v-card :disabled="visibility">
          <v-card-title>
            <h2 id="title">Solicitud de tarjetas</h2>
          </v-card-title>
          <v-divider id="div"></v-divider>
          <v-card-text>
            <h3 id="title">Datos personales</h3>
            <br />
            <v-text-field
              type="text"
              outlined
              dense
              v-model="name"
              :rules="nameRules"
              label="Nombre"
              required
            >
            </v-text-field>

            <v-text-field
              type="text"
              outlined
              dense
              v-model="surName"
              :rules="surNameRules"
              label="Apellido"
              required
            >
            </v-text-field>

            <v-text-field
              type="text"
              outlined
              dense
              v-model="email"
              :rules="emailRules"
              label="Correo"
              required
            >
            </v-text-field>

            <v-row no-gutters>
              <v-col cols="4" class="pr-1">
                <v-select
                  outlined
                  dense
                  v-model="documentType"
                  :items="documentTypeItems"
                  label="Tipo de documento"
                  :rules="[(v) => !!v || 'Es necesario seleccionar un valor']"
                  required
                  type="text"
                >
                </v-select>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  dense
                  type="number"
                  v-model="document"
                  label="Documento"
                  required
                  :rules="[(v) => !!v || 'El documento  es requerido']"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-text-field
              outlined
              dense
              v-model="idRappi"
              label="Id Rappi"
              required
              type="number"
              :rules="idRappiRules"
            >
            </v-text-field>

            <v-text-field
              outlined
              type="number"
              dense
              v-model="phone"
              label="Celular"
              required
              :rules="phoneRules"
            >
            </v-text-field>

            <h3 id="title">Dirección</h3>
            <br />

            <v-row class="mb-6 alinear-xl" no-gutters>
              <v-col class="pl-1" cols="6" lg="2">
                <v-select
                  xs="2"
                  dense
                  outlined
                  label="Identificador"
                  v-model="addresIdent1"
                  :items="addressIdentItems"
                  :rules="[(v) => !!v || 'Es necesario seleccionar un valor']"
                  @change="update"
                >
                </v-select>
              </v-col>
              <v-col class="pl-1 pr-0" cols="3" lg="1">
                <v-text-field
                  :rules="[(v) => !!v || 'Es necesario seleccionar un valor']"
                  dense
                  outlined
                  label="Numero"
                  v-model="addresNumber1"
                  type="number"
                  @change="update"
                  required
                ></v-text-field>
              </v-col>

              <v-col class="pl-1 pr-0">
                <v-select
                  dense
                  outlined
                  label="Letra"
                  v-model="addresLetter1"
                  :items="addresLettersItems"
                  @change="update"
                ></v-select>
              </v-col>

              <v-col class="pl-1 pr-0">
                <v-select
                  dense
                  label="Bis"
                  outlined
                  v-model="bis1"
                  :items="addresBis"
                  @change="update"
                >
                </v-select>
              </v-col>

              <v-col class="pl-1 pr-0" cols="4" lg="1">
                <v-select
                  dense
                  label="Orientacion"
                  outlined
                  v-model="addresOrientation1"
                  :items="addresOrientationItems"
                  @change="update"
                >
                </v-select>
              </v-col>
              <center>
                <h2 class="pl-1 pr-1 pt-2">#</h2>
              </center>
              <v-col class="pl-0 pr-0" cols="3" lg="1">
                <v-text-field
                  dense
                  label="Numero"
                  outlined
                  type="number"
                  v-model="addresNumber2"
                  :rules="numberRules"
                  @change="update"
                ></v-text-field>
              </v-col>

              <v-col class="pl-1 pr-0">
                <v-select
                  dense
                  label="Letra"
                  outlined
                  v-model="addresLetter2"
                  :items="addresLettersItems"
                  @change="update"
                ></v-select>
              </v-col>

              <v-col class="pl-1 pr-0">
                <v-select
                  dense
                  label="Bis"
                  outlined
                  v-model="bis2"
                  :items="addresBis"
                  @change="update"
                >
                </v-select>
              </v-col>

              <v-col class="pl-1 pr-0" cols="4" lg="1">
                <v-select
                  dense
                  label="Orientacion"
                  outlined
                  v-model="addresOrientation2"
                  :items="addresOrientationItems"
                  @change="update"
                >
                </v-select>
              </v-col>

              <center>
                <h2 class="pl-1 pr-1 pt-2">-</h2>
              </center>

              <v-col class="pl-0 pr-0" cols="3" lg="1">
                <v-text-field
                  dense
                  label="Numero"
                  outlined
                  type="number"
                  v-model="addresNumber3"
                  :rules="numberRules"
                  @change="update"
                ></v-text-field>
              </v-col>

              <v-col class="pl-1" cols="3" lg="1">
                <v-select
                  dense
                  label="Letra"
                  outlined
                  v-model="addresLetter3"
                  :items="addresLettersItems"
                  @change="update"
                ></v-select>
              </v-col>
            </v-row>
            <v-text-field
              dense
              label="La direccion es"
              outlined
              v-model="addres"
              readonly
            ></v-text-field>
            <v-row no-gutters>
              <v-col class="pr-1">
                <v-select
                  v-model="selectedDepartament"
                  :items="Object.keys(places)"
                  dense
                  outlined
                  label="Departamento"
                  :rules="[(v) => !!v || 'Es necesario seleccionar un valor']"
                  required
                >
                </v-select>
              </v-col>
              <v-col>
                <v-select
                  :disabled="selectedDepartament == ''"
                  dense
                  label="Ciudad"
                  outlined
                  v-model="selectedCity"
                  :items="cities"
                  required
                  :rules="[(v) => !!v || 'Es necesario seleccionar un valor']"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider id="div"></v-divider>
          <v-card-actions>
            <v-btn id="btn-validate" class="mr-4" @click="validate">
              Validar
            </v-btn>

            <v-btn class="mr-4" @click="reset" id="btn-clean">
              Limpiar campos
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      <br />
      <v-alert
        transition="scale-transition"
        :value="alert"
        dense
        text
        type="success"
      >
        El fomulario fue llenado <strong>correctamente</strong>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
const { GoogleSpreadsheet } = require("google-spreadsheet");
const creds = require("@/credenciales.json");

export default {
  data: () => ({
    alert: false,
    visibility: false,
    valid: true,
    name: "",
    idRappiRules: [
      (v) =>
        (v && v.length <= 8) || "El nombre debe tener almenos de 8 caracteres",
      (v) => !!v || "El Id de Rappi es requerido",
    ],
    numberRules: [
      (v) => v > 0 || "El valor debe ser mayor a cero",
      (v) => !!v || "Es necesario seleccionar un valor",
    ],
    nameRules: [(v) => !!v || "El nombre es requerido"],
    surName: "",
    phoneRules: [
      (v) => !!v || "El celular es requerido",
      (v) => (v && v.length == 10) || "El celular debe tener 10 caracteres",
    ],
    surNameRules: [(v) => !!v || "El apellido  es requerido"],
    documentRules: [
      (v) => !!v || "El documento es requerido",
      (v) =>
        (v && v.length <= 20) ||
        "El documento debe tener almenos de 20 caracteres",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "El correo es requerido",
      (v) => /.+@.+\..+/.test(v) || "El email debe ser válido",
    ],
    documentType: "",
    phone: "",
    documentTypeItems: [
      "CC - Cédula de Ciudadanía",
      "CE - Cédula Extranjería",
      "Otro",
    ],
    addressIdentItems: [
      "",
      "Autopista",
      "Avenida Carrera",
      "Avenida Calle",
      "Bulevar",
      "Calle",
      "Carrera",
      "Circular",
      "Circunvalar",
      "Diagonal",
      "Lote",
      "Manzana",
      "Transversal",
      "Variante",
    ],
    addresLettersItems: [
      "",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "X",
      "Y",
      "Z",
    ],
    addresOrientationItems: ["", "Este", "Oeste", "Norte", "Sur"],
    addresBis: ["", "Bis"],
    addresIdent1: "",
    addresNumber1: " ",
    addresLetter1: "",
    addresOrientation1: "",
    addresIdent2: "",
    addresNumber2: " ",
    addresLetter2: "",
    addresOrientation2: "",
    addresNumber3: " ",
    addresLetter3: "",
    numberPhone: "",
    document: "",
    addres: "",
    idRappi: "",
    bis2: "",
    bis1: "",
    selectedDepartament: "",
    selectedCity: "",
    cities: [],
    places: {
      Antioquia: [
        "Abejorral",
        "Abriaqui",
        "Alejandria",
        "Amaga",
        "Amalfi",
        "Andes",
        "Angelopolis",
        "Angostura",
        "Anori",
        "Antioquia",
        "Anza",
        "Apartado",
        "Arboletes",
        "Argelia",
        "Armenia",
        "Barbosa",
        "Belmira",
        "Bello",
        "Betania",
        "Betulia",
        "Bolivar",
        "Briseno",
        "Buritica",
        "Caceres",
        "Caicedo",
        "Caldas",
        "Campamento",
        "Canasgordas",
        "Caracoli",
        "Caramanta",
        "Carepa",
        "Carmen de Viboral",
        "Carolina",
        "Caucasia",
        "Chigorodo",
        "Cisneros",
        "Cocorna",
        "Concepcion",
        "Concordia",
        "Copacabana",
        "Dabeiba",
        "Don Matias",
        "Ebejico",
        "El Bagre",
        "Entrerrios",
        "Envigado",
        "Fredonia",
        "Frontino",
        "Giraldo",
        "Girardota",
        "Gomez Plata",
        "Granada",
        "Guadalupe",
        "Guarne",
        "Guatape",
        "Heliconia",
        "Hispania",
        "Itagui",
        "Ituango",
        "Jardin",
        "Jerico",
        "La Ceja",
        "La Estrella",
        "La Pintada",
        "La Union",
        "Liborina",
        "Maceo",
        "Marinilla",
        "Medellin",
        "Montebello",
        "Murindo",
        "Mutata",
        "Narino",
        "Necocli",
        "Nechi",
        "Olaya",
        "Penol",
        "Peque",
        "Pueblorrico",
        "Puerto Berrio",
        "Puerto Nare",
        "Puerto Triunfo",
        "Remedios",
        "Retiro",
        "Rionegro",
        "Sabanalarga",
        "Sabaneta",
        "Salgar",
        "San Andres",
        "San Carlos",
        "San francisco",
        "San Jeronimo",
        "San Jose de Montana",
        "San Juan de Uraba",
        "San Luis",
        "San Pedro",
        "San Pedro de Uraba",
        "San Rafael",
        "San Roque",
        "San Vicente",
        "Santa Barbara",
        "Santa Rosa de Osos",
        "Santo Domingo",
        "Santuario",
        "Segovia",
        "Sonson",
        "Sopetran",
        "Tamesis",
        "Taraza",
        "Tarso",
        "Titiribi",
        "Toledo",
        "Turbo",
        "Uramita",
        "Urrao",
        "Valdivia",
        "Valparaiso",
        "Vegachi",
        "Venecia",
        "Vigia del Fuerte",
        "Yali",
        "Yarumal",
        "Yolombo",
        "Yondo (Casabe)",
        "Zaragoza",
      ],
      Atlantico: [
        "Barranquilla",
        "Baranoa",
        "Campo de la Cruz",
        "Candelaria",
        "Galapa",
        "Juan de Acosta",
        "Luruaco",
        "Malambo",
        "Manati",
        "Palmar de Varela",
        "Piojo",
        "Polonuevo",
        "Ponedera",
        "Puerto Colombia",
        "Repelon",
        "Sabanagrande",
        "Sabanalarga",
        "Santa Lucia",
        "Santo Tomas",
        "Soledad",
        "Suan",
        "Tubara",
        "Usiacuri",
        "Cartagena",
        "Achi",
        "Altos del Rosario",
        "Arenal",
        "Arjona",
        "Arroyohondo",
        "Barranco de Loba",
        "Calamar",
        "Cantagallo",
        "Cicuto",
        "Cordoba",
        "Clemencia",
        "El Carmen de Bolivar",
        "El Guamo",
        "El Penon",
        "Hatillo de Loba",
        "Magangue",
        "Mahates",
        "Margarita",
        "Maria la Baja",
        "Montecristo",
        "Mompos",
        "Morales",
        "Pinillos",
        "Regidor",
        "Rio Viejo",
        "San Cristobal",
        "San Estanislao",
        "San Fernando",
        "San Jacinto",
        "San Jacinto del Cauca",
        "San Juan Nepomuceno",
        "San Martin de Loba",
        "San Pablo",
        "Santa Catalina",
        "Santa Rosa",
        "Santa Rosa del Sur",
        "Simiti",
        "Soplaviento",
        "Talaigua Nuevo",
        "Tiquisio (Puerto Rico)",
        "Turbaco",
        "Turbana",
        "Villanueva",
        "Zambrano",
      ],
      Caldas: [
        "Aguadas",
        "Anserma",
        "Aranzazu",
        "Belalcazar",
        "Chinchina",
        "Filadelfia",
        "La Dorada",
        "La Merced",
        "Manizales",
        "Manzanares",
        "Marmato",
        "Marquetalia",
        "Marulanda",
        "Neira",
        "Pacora",
        "Palestina",
        "Pensilvania",
        "Riosucio",
        "Risaralda",
        "Salamina",
        "Samana",
        "San Jose",
        "Supia",
        "Victoria",
        "Villamaria",
        "Viterbo",
      ],
      Casanare: [
        "Aguazul",
        "Chameza",
        "Hato Corozal",
        "La Salina",
        "Mani",
        "Monterrey",
        "Nunchia",
        "Orocue",
        "Paz de Ariporo",
        "Pore",
        "Recetor",
        "Sabalarga",
        "Sacama",
        "San Luis de Palenque",
        "Tamara",
        "Tauramena",
        "Trinidad",
        "Villanueva",
        "Yopal",
      ],
      Cauca: [
        "Almaguer",
        "Argelia",
        "Balboa",
        "Bolivar",
        "Buenos Aires",
        "Cajibio",
        "Caldono",
        "Caloto",
        "Corinto",
        "El Tambo",
        "Florencia",
        "Guapi",
        "Inza",
        "Jambalo",
        "La Sierra",
        "La Vega",
        "Lopez (Micay)",
        "Mercaderes",
        "Miranda",
        "Morales",
        "Padilla",
        "Paez (Belalcazar)",
        "Patia (El Bordo)",
        "Piamonte",
        "Piendamo",
        "Popayan",
        "Puerto Tejada",
        "Purace (Coconuco)",
        "Rosas",
        "San Sebastian",
        "Santander de Quilichao",
        "Santa Rosa",
        "Silvia",
        "Sotara (Paispamba)",
        "Suarez",
        "Timbio",
        "Timbiqui",
        "Toribio",
        "Totoro",
      ],
      Cesar: [
        "Aguachica",
        "Agustin Codazzi",
        "Astrea",
        "Becerril",
        "Bosconia",
        "Chimichagua",
        "Chiriguana",
        "Curumani",
        "El Copey",
        "El Paso",
        "Gamarra",
        "Gonzalez",
        "La Gloria",
        "La Jagua de Ibirico",
        "Manaure Balcon Cesar",
        "Pailitas",
        "Pelaya",
        "Pueblo Bello",
        "Rio de Oro",
        "La Paz (Robles)",
        "San Alberto",
        "San Diego",
        "San Martin",
        "Tamalameque",
        "Valledupar",
      ],
      Cordoba: [
        "Ayapel",
        "Buenavista",
        "Canalete",
        "Cerete",
        "Chima",
        "Chinu",
        "Cienaga de Oro",
        "Cotorra",
        "La Apartada (Frontera)",
        "Lorica",
        "Los Cordobas",
        "Momil",
        "Monitos",
        "Montelibano",
        "Monteria",
        "Planeta Rica",
        "Pueblo Nuevo",
        "Puerto Escondido",
        "Puerto Libertador",
        "Purisima",
        "Sahagun",
        "San Andres Sotavento",
        "San Antero",
        "San Bernardo del Viento",
        "San Carlos",
        "San Pelayo",
        "Tierralta",
        "Valencia",
      ],
      Cundinamarca: [
        "Agua de Dios",
        "Alban",
        "Anapoima",
        "Anolaima",
        "Arbelaez",
        "Beltran",
        "Bogota",
        "Bituima",
        "Bojaca",
        "Cabrera",
        "Cachipay",
        "Cajica",
        "Caparrapi",
        "Caqueza",
        "Carmen de Carupa",
        "Chaguani",
        "Chia",
        "Chipaque",
        "Choachi",
        "Choconta",
        "Cogua",
        "Cota",
        "Cucunuba",
        "El Colegio",
        "El Penon",
        "El Rosal",
        "Facatativa",
        "Fomeque",
        "Fosca",
        "Funza",
        "Fuquene",
        "Fusagasuga",
        "Gachala",
        "Gachancipa",
        "Gacheta",
        "Gama",
        "Girardot",
        "Granada",
        "Guacheta",
        "Guaduas",
        "Guasca",
        "Guataqui",
        "Guatavita",
        "Guayabal de Siquima",
        "Guayabetal",
        "Gutierrez",
        "Jerusalen",
        "Junin",
        "La Calera",
        "La Mesa",
        "La Palma",
        "La Pena",
        "La Vega",
        "Lenguazaque",
        "Macheta",
        "Madrid",
        "Manta",
        "Medina",
        "Mosquera",
        "Narino",
        "Nemocon",
        "Nilo",
        "Nimaima",
        "Nocaima",
        "Venecia (Ospina Perez)",
        "Pacho",
        "Paime",
        "Pandi",
        "Paratebueno",
        "Pasca",
        "Puerto Salgar",
        "Puli",
        "Quebradanegra",
        "Quetame",
        "Quipile",
        "Rafael",
        "Ricaurte",
        "San Antonio de Tequendama",
        "San Bernardo",
        "San Cayetano",
        "San Francisco",
        "San Juan de Rioseco",
        "Sasaima",
        "Sesquile",
        "Sibate",
        "Silvania",
        "Simijaca",
        "Soacha",
        "Sopo",
        "Subachoque",
        "Suesca",
        "Supata",
        "Susa",
        "Sutatausa",
        "Tabio",
        "Tausa",
        "Tena",
        "Tenjo",
        "Tibacuy",
        "Tibirita",
        "Tocaima",
        "Tocancipa",
        "Topaipi",
        "Ubala",
        "Ubaque",
        "Ubate",
        "Une",
        "utica",
        "Vergara",
        "Viani",
        "Villagomez",
        "Villapinzon",
        "Villeta",
        "Viota",
        "Yacopi",
        "Zipacon",
        "Zipaquira",
      ],
      Huila: [
        "Acevedo",
        "Agrado",
        "Aipe",
        "Algeciras",
        "Altamira",
        "Baraya",
        "Campoalegre",
        "Colombia",
        "Elias",
        "Garzon",
        "Gigante",
        "Guadalupe",
        "Hobo",
        "Iquira",
        "Isnos",
        "La Argentina",
        "La Plata",
        "Nataga",
        "Neiva",
        "Oporapa",
        "Paicol",
        "Palermo",
        "Palestina",
        "Pital",
        "Pitalito",
        "Rivera",
        "Saladoblanco",
        "San Agustin",
        "Santa Maria",
        "Suaza",
        "Tarqui",
        "Tesalia",
        "Tello",
        "Teruel",
        "Timana",
        "Villavieja",
        "Yaguara",
      ],
      Magdalena: [
        "Aracataca",
        "Ariguani (El Dificil)",
        "Cerro San Antonio",
        "Chivolo",
        "Cienaga",
        "El Banco",
        "El Pinon",
        "El Reten",
        "Fundacion",
        "Guamal",
        "Pedraza",
        "Pijino del Carmen",
        "Pivijay",
        "Plato",
        "Publoviejo",
        "Remolino",
        "Salamina",
        "San Sebastian de Buuenavista",
        "San Zenon",
        "Santa Ana",
        "Santa Marta",
        "Sitionuevo",
        "Tenerife",
      ],
      Meta: [
        "Acacias",
        "Barranca de Upia",
        "Cabuyaro",
        "Castilla la Nueva",
        "Cubarral",
        "Cumaral",
        "El Calvario",
        "El Castillo",
        "El Dorado",
        "Fuente de Oro",
        "Granada",
        "Guamal",
        "Mapiripan",
        "Mesetas",
        "La Macarena",
        "La Uribe",
        "Lejanias",
        "Puerto Concordia",
        "Puerto Gaitan",
        "Puerto Lopez",
        "Puerto Lleras",
        "Puerto Rico",
        "Restrepo",
        "San Carlos de Guaroa",
        "San Juan de Arama",
        "San Juanito",
        "San Martin",
        "Villavicencio",
        "Vistahermosa",
      ],
      Nariño: [
        "Alban (San Jose)",
        "Aldana",
        "Ancuya",
        "Arboleda (Berruecos)",
        "Barbacoas",
        "Belen",
        "Buesaco",
        "Colon (Genova)",
        "Consaca",
        "Contadero",
        "Cordoba",
        "Cuaspud (Carlosama)",
        "Cumbal",
        "Cumbitara",
        "Chachagui",
        "El Charco",
        "El Rosario",
        "El Tablon",
        "El Tambo",
        "Francisco Pizarro",
        "Funes",
        "Guachucal",
        "Guaitarilla",
        "Gualmatan",
        "Iles",
        "Imues",
        "Ipiales",
        "La Cruz",
        "La Florida",
        "La Llanada",
        "La Tola",
        "La Union",
        "Leiva",
        "Linares",
        "Los Andes (Sotomayor)",
        "Magui (Payan)",
        "Mallama (Piedrancha)",
        "Mosquera",
        "Olaya",
        "Ospina",
        "Pasto",
        "Policarpa",
        "Potosi",
        "Providencia",
        "Puerres",
        "Pupiales",
        "Ricaurte",
        "Roberto Payan (San Jose)",
        "Samaniego",
        "Sandona",
        "San Bernardo",
        "San Lorenzo",
        "San Pablo",
        "San Pedro de Cartago",
        "Santa Barbara (Iscuande)",
        "Santa Cruz (Guachavez)",
        "Sapuyes",
        "Taminango",
        "Tangua",
        "Tumaco",
        "Tuquerres",
        "Yacuanquer",
      ],
      "Norte de Santander": [
        "Abrego",
        "Arboledas",
        "Bochalema",
        "Bucarasica",
        "Cacota",
        "Cachira",
        "Chinacota",
        "Chitaga",
        "Convencion",
        "Cucuta",
        "Cucutilla",
        "Durania",
        "El Carmen",
        "El Tarra",
        "El Zulia",
        "Gramalote",
        "Hacari",
        "Herran",
        "Labateca",
        "La Esperanza",
        "La Playa",
        "Los Patios",
        "Lourdes",
        "Mutiscua",
        "Ocana",
        "Pamplona",
        "Pamplonita",
        "Puerto Santander",
        "Ragonvalia",
        "Salazar",
        "San Calixto",
        "San Cayetano",
        "Santiago",
        "Sardinata",
        "Silos",
        "Teorama",
        "Tibu",
        "Toledo",
        "Villacaro",
        "Villa del Rosario",
      ],
      Quindio: [
        "Armenia",
        "Buenavista",
        "Calarca",
        "Circasia",
        "Cordoba",
        "Filandia",
        "Genova",
        "La Tebaida",
        "Montenegro",
        "Pijao",
        "Quimbaya",
        "Salento",
      ],
      Risaralda: [
        "Apia",
        "Balboa",
        "Belen de Umbria",
        "Dos Quebradas",
        "Guatica",
        "La Celia",
        "La Virginia",
        "Marsella",
        "Mistrato",
        "Pereira",
        "Pueblo Rico",
        "Quinchia",
        "Santa Rosa de Cabal",
        "Santuario",
      ],
      Santander: [
        "Aguada",
        "Albania",
        "Aratoca",
        "Barbosa",
        "Barichara",
        "Barrancabermeja",
        "Betulia",
        "Bolivar",
        "Bucaramanga",
        "Cabrera",
        "California",
        "Capitanejo",
        "Carcasi",
        "Cepita",
        "Cerrito",
        "Charala",
        "Charta",
        "Chima",
        "Chipata",
        "Cimitarra",
        "Concepcion",
        "Confines",
        "Contratacion",
        "Coromoro",
        "Curiti",
        "El Carmen",
        "El Guacamayo",
        "El Penon",
        "El Playon",
        "Encino",
        "Enciso",
        "Florian",
        "Floridablanca",
        "Galan",
        "Gambita",
        "Giron",
        "Guaca",
        "Guadalupe",
        "Guapota",
        "Guavata",
        "Guepsa",
        "Hato",
        "Jesus Maria",
        "Jordan",
        "La Belleza",
        "Landazuri",
        "La Paz",
        "Lebrija",
        "Los Santos",
        "Macaravita",
        "Malaga",
        "Matanza",
        "Mogotes",
        "Molagavita",
        "Ocamonte",
        "Oiba",
        "Onzaga",
        "Palmar",
        "Palmas del Socorro",
        "Paramo",
        "Pie de Cuesta",
        "Pinchote",
        "Puente Nacional",
        "Puerto Parra",
        "Puerto Wilches",
        "Rionegro",
        "Sabana de Torres",
        "San Andres",
        "San Benito",
        "San Gil",
        "San Joaquin",
        "San Jose de Miranda",
        "San Miguel",
        "San Vicente de Chucuri",
        "Santa Barbara",
        "Santa Helena del Opon",
        "Simacota",
        "Socorro",
        "Suaita",
        "Sucre",
        "Surata",
        "Tona",
        "Valle de San Jose",
        "Velez",
        "Vetas",
        "Villanueva",
        "Zapatoca",
      ],
      Sucre: [
        "Buenavista",
        "Caimito",
        "Coloso (Ricaurte)",
        "Corozal",
        "Chalan",
        "Galeras (Nueva Granada)",
        "Guaranda",
        "La Union",
        "Los Palmitos",
        "Majagual",
        "Morroa",
        "Ovejas",
        "Palmito",
        "Sampues",
        "San Benito Abad",
        "San Juan de Betulia",
        "San Marcos",
        "San Onofre",
        "San Pedro",
        "Since",
        "Sincelejo",
        "Sucre",
        "Tolu",
        "Toluviejo",
      ],
      Tolima: [
        "Alpujarra",
        "Alvarado",
        "Ambalema",
        "Anzoategui",
        "Armero (Guayabal)",
        "Ataco",
        "Cajamarca",
        "Carmen de Apicala",
        "Casabianca",
        "Chaparral",
        "Coello",
        "Coyaima",
        "Cunday",
        "Dolores",
        "Espinal",
        "Falan",
        "Flandes",
        "Fresno",
        "Guamo",
        "Herveo",
        "Honda",
        "Ibague",
        "Icononzo",
        "Lerida",
        "Libano",
        "Mariquita",
        "Melgar",
        "Murillo",
        "Natagaima",
        "Ortega",
        "Palocabildo",
        "Piedras",
        "Planadas",
        "Prado",
        "Purificacion",
        "Rioblanco",
        "Roncesvalles",
        "Rovira",
        "Saldana",
        "San Antonio",
        "San Luis",
        "Santa Isabel",
        "Suarez",
        "Valle de San Juan",
        "Venadillo",
        "Villahermosa",
        "Villarrica",
      ],
      Valle: [
        "Alcala",
        "Andalucia",
        "Ansermanuevo",
        "Argelia",
        "Bolivar",
        "Buenaventura",
        "Buga",
        "Bugalagrande",
        "Caicedonia",
        "Cali",
        "Calima (Darien)",
        "Candelaria",
        "Cartago",
        "Dagua",
        "El aguila",
        "El Cairo",
        "El Cerrito",
        "El Dovio",
        "Florida",
        "Ginebra",
        "Guacari",
        "Jamundi",
        "La Cumbre",
        "La Union",
        "La Victoria",
        "Obando",
        "Palmira",
        "Pradera",
        "Restrepo",
        "Riofrio",
        "Roldanillo",
        "San Pedro",
        "Sevilla",
        "Toro",
        "Trujillo",
        "Tulua",
        "Ulloa",
        "Versalles",
        "Vijes",
        "Yotoco",
        "Yumbo",
        "Zarzal",
      ],
    },
  }),
  watch: {
    selectedDepartament: function () {
      this.cities = [];
      this.selectedCity = "";
      if (this.selectedDepartament.length > 0) {
        this.cities = this.places[this.selectedDepartament];
      }
    },
  },

  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.visibility = true;
        this.addRow();
        this.alert = !this.alert;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    update() {
      this.addres = "";
      this.addres += `${this.addresIdent1} ${this.addresNumber1}${this.addresLetter1}`;
      if (this.bis1 != "") {
        this.addres += ` ${this.bis1}`;
      }
      if (this.addresOrientation1 != "") {
        this.addres += ` ${this.addresOrientation1}`;
      }
      if (this.addresNumber2 != " ") {
        this.addres += ` # `;
      }
      this.addres += `${this.addresNumber2}${this.addresLetter2}`;
      if (this.bis2 != "") {
        this.addres += ` ${this.bis2}`;
      }
      if (this.addresOrientation2 != "") {
        this.addres += ` ${this.addresOrientation2}`;
      }
      if (this.addresNumber3 != " ") {
        this.addres += ` - `;
      }
      this.addres += `${this.addresNumber3}${this.addresLetter3}`;
    },
    async addRow() {
      const newRow = {
        date: new Date(),
        name: this.name,
        surName: this.surName,
        idRappi: this.idRappi,
        typeDocument: this.documentType,
        document: this.document,
        phone: this.phone,
        email: this.email,
        departament: this.selectedDepartament,
        city: this.selectedCity,
        addres: this.addres,
      };

      const doc = new GoogleSpreadsheet(
        "1JBwFF46E1giTMqK9DpRSM7kkoQCMcR5v_BhU3l2cewI"
      );
      await doc.useServiceAccountAuth(creds);
      await doc.loadInfo();
      const sheet = doc.sheetsByIndex[0];
      await sheet.addRow(newRow);
    },
  },
};
</script>
